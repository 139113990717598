import React from "react"
import { useSelector } from 'react-redux';
import { 
  Container, 
  FeatureImage 
} from "../components"
import { H1 } from "../elements"
const NotFound = ({ location }) => {
  const locale = useSelector((state) => state.localeString);
  const isDark = useSelector((state) => state.isDark);

  let text_404 = require("../utils/lingua")(locale)("404")
  return (
    <Container 
      pathname={location.pathname}
      locale={locale}
      isDark={isDark}
    >
        <FeatureImage/>
        <H1 textAlign="center" margin="0 0 1rem 0 ">
          {text_404}
        </H1>
    </Container>
  )
}
export default NotFound
